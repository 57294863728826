.personalDetails {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerPersonalDetails {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.personalDetailsHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.personalDetailsHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.bottomBorder {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--dropColor);
}

.personalDetailsLeftIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.personalDetailsLeftIconSvg {
    margin-right: 10px;
    height: 18px;
}

.personalDetailsHead h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.bottomBorder h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}


/* dropDown */

.personalDetailsDesc {
    width: 100%;
    /* height: 500px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
}

.personalDetailsDesc h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;

}

.personalInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.personalDetailsDescLeft {
    width: 54%;
}

.personalDetailsDescLeft h2 {
    font-size: 14px;
    font-weight: 500;
    color: var(--textColor);
    margin-bottom: 5px;
}

.bioLabel {
    font-size: 14px;
    font-weight: 500;
    color: var(--textColor);
    margin-bottom: 5px;
}

.personalDetailsDescLeft h3 {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColorDark);
    margin-bottom: 20px;
}

.biocontent {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColorDark);
    margin-bottom: 20px;
}

.personalDetailsDescLeftOpacity {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.2;
    margin-bottom: 20px;
}

.personalDetailsDescRight {
    width: 50%;
}

.personalDetailsDescRight h2 {
    font-size: 14px;
    font-weight: 500;
    color: var(--textColor);
    margin-bottom: 5px;
}

.personalDetailsDescRight h3 {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColorDark);
    margin-bottom: 20px;
}

/* overlay */
.personalOverlay {
    width: 65%;
    height: 95%;
    background-color: white;
    display: flex;
    overflow-y: scroll;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.updatePersonalDetails {
    width: 100%;
    height: 100%;
    padding: 30px;

}

.updatePersonalDetails h1 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.5;
    margin-bottom: 5px;
}

.updatePersonalDetailsFlex {
    display: flex;
    width: 100%;
}

.updatePersonalDetailsLeft {
    width: 48%;
    margin-right: 2%
}

.updatePersonalDetailsLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;

}

.biohead {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.updatePersonalDetailsLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
    border: 1px solid var(--borderColor);
}

.updatePersonalDetailsLeft select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
    border: 1px solid var(--borderColor);
}

.updatePersonalDetailsRight {
    width: 48%;
    margin-left: 2%;
}

.updatePersonalDetailsRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.updatePersonalDetailsRight input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.biotext {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.updatePersonalDetailsRight select {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.candidateAddressPersonal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.candidateAddressPersonal input {
    width: 280px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.candidateAddressPersonal select {
    width: 130px;
    padding: 11px;
    border-radius: 8px;
    margin-top: 8px;
    border: 1px solid var(--borderColor);
}

.personalDetailNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.personalDetailNumber input {
    width: 280px;
    padding: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 8px;
    border: 1px solid var(--borderColor);
}

.personalDetailNumber select {
    width: 130px;
    padding: 11px;
    border-radius: 8px;
    margin-top: 8px;
    border: 1px solid var(--borderColor);
}

/* icon */
.profileCompleteIcon {
    color: #14B8A6;
    margin-right: 10px;
    font-size: 22px;
}

.warningIcon {
    color: red;
    font-size: 22px;
    margin-right: 10px;
    /* display: none; */
}

.completedIndication {
    position: absolute;
    top: 20%;
    right: 10%;
    padding: 3px 10px;
    /* border-radius: 1000px; */
    background-color: #CCFBF1;
    border-radius: 5px;
}

.InCompleteIndication {
    position: absolute;
    top: 20%;
    right: 10%;
    padding: 3px 10px;
    /* border-radius: 1000px; */
    background-color: #FEE2E2;
    border-radius: 5px;
}

.completedIndication p {
    font-size: 12px;
    color: #14B8A6;
}

.InCompleteIndication p {
    font-size: 12px;
    color: #EF4444;
}

@media only screen and (max-width: 600px) {
    .personalOverlay {
        width: 100%;
        height: 100%;
    }

    .updatePersonalDetails {
        overflow-y: scroll;
        padding: 20px;
    }

    .innerPersonalDetails {
        height: 10%;
    }

    .updatePersonalDetailsFlex {
        flex-direction: column;
    }

    .updatePersonalDetailsRight {
        width: 100%;
        margin-left: 0px;
    }

    .updatePersonalDetailsLeft {
        width: 100%;
        margin-right: 0px;
    }
}