.AdminClientProfileComp {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    margin-bottom: 30px;
}

.AdminClientProfileCompSearch {
    padding: 10px;
    position: relative;
    border: 1px solid var(--borderColor);
    border-radius: 8px 8px 0px 0px;

}

.AdminClientProfileCompSearch input {
    width: 100%;
    padding: 10px;
    padding-left: 50px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.AdminClientProfileCompSearch img {
    position: absolute;
    top: 25%;
    left: 2%;
}

/* table */

.AdminClientTable {
    width: 100%;
    height: 100%;
    border: 1px solid var(--borderColor);
    border-top: none;
    border-radius: 8px;
}

.AdminTableHead {
    border-bottom: 1px solid var(--borderColor);
}

.AdminTableHead th {
    padding: 15px 15px;
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.5;
}

.tableName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tableName h1 {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.tableName img {
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
}

.adminTableRow td {
    padding: 15px;

}

.tableLocation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tableLocation img {
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.tableLocation h1 {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.AdminClientTable h1 {
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
}


.viewButton {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--primaryColor);
}

/* status */
.agreementStatus {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    border-radius: 20px;
}

.inActive {
    background-color: #FEE2E2;
    color: #EF4444;
}

.Active {
    background-color: #dcfce7;
    color: green;
}

.status {
    text-align: center;
    font-size: 13px;
    padding: 3px;
    font-weight: 400;
    border-radius: 20px;
}

.inComplete {
    color: #6B7280;
    background-color: #F3F4F6;
    display: unset;
    padding: 5px 10px;
}


/* tablePagination */
.tablePagination {
    width: 100%;
    padding: 40px;
    border: 1px solid var(--borderColor);
    border-top: none;
    border-radius: 0px 0px 8px 8px;
}


/* page css for adminprofilepage */

@media only screen and (max-width: 600px) {
    .AdminClientProfileCompTable {
        overflow: scroll;
    }

    .AdminClientProfileCompSearch img {
        left: 5%;
    }

    .AdminClientTable {
        overflow-x: scroll;
    }

    .tablePagination {
        padding: 10px;
    }

    .shortListButtonDisable {
        width: 48%;
        margin-right: 0px;
        padding: 5px;
    }

    .shortListButtonActive {
        width: 48%;
        margin-right: 0px;
        padding: 5px;
    }

    .shortListButtonLeft {
        width: 100%;
    }
}