.travelHistory {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}


.innerTravelHistory {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.travelHistoryHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.travelHistoryHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.bottomBorder {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--dropColor);
}

.travelHistoryLeftIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.travelHistoryLeftIconSvg {
    margin-right: 10px;
    height: 18px;
}

.travelHistory h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.travelHistoryDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
}

.travelHistoryDesc h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-left: 0;
}

.travelHistoryDesc h2 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 3px;
}

.travelHistoryDescFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.travelHistoryDescFlex h3 {
    font-size: 14px;
    font-weight: 500;
}

.travelHistoryDescFlex h4 {
    font-size: 14px;
    font-weight: 500;
}

.travelHistoryDescFlex p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;

}

.travelHistoryDescFlex p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;
}

.travelHistoryDescFlexLast {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-top: 30px;

}

.travelHistoryDescFlexLast h4 {
    font-size: 14px;
    font-weight: 500;
}

.travelHistoryDescFlexLast p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;

}

.travelHistoryHighlight {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #F5F3FF;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-top: 20px;
}

.projectDetailsHighlight p {
    /* width: 80%; */
    font-weight: 400;
    margin-left: 15px;
    font-size: 15px;
    color: var(--textColor);
}

.projectDetailsHighlight img {
    margin-Top: 5px;
}

.projectDetailsDesc h6 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;
}

.travelGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;


}

.travelGridOne {

    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    padding: 25px;
}

.travelGridOne h3 {
    display: flex;
    align-items: baseline;
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    color: var(--textColor);

}

.travelGridOne p {
    margin-left: 3px;
    font-size: 13px;
    font-weight: 500;
    opacity: 0.5;
}

/* overlay */
.travelHistoryDescOverlay {
    width: 65%;
    height: 80%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.travelHistoryDescOverlayPage1 {
    width: 65%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.innerTravelHistoryOverlay {
    height: 90%;
    overflow-y: scroll;
}

.travelHistoryDescOverlayInner {
    padding: 20px;
}

.travelHistoryHeadOverlay {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.travelHistoryDescOverlay h6 {
    font-size: 14px;
    opacity: 0.5;

}

.travelHistoryDescOverlayh6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
    padding-bottom: 0%;

}

.travelRadio {
    padding: 20px;
    padding-bottom: 0px;

}

.travelRadioOne {
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 15px;

}

.travelRadioOneDesc h3 {
    font-size: 15px;
    font-weight: 600;

}

.travelRadioOneDesc p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.4;
}

.travelRadioOne input {
    margin-top: 5px;
}

.travelRadioOneDesc {
    margin-left: 15px;
}

.travelRadio h6 {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.5;
    margin-bottom: 15px;
}

.travelUpdate {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 15px;
}

.travelUpdateFlex {
    display: flex;
}

.travelUpdateFlexLeft {
    width: 31%;
    margin-right: 3%;
}

.travelUpdateFlexCenter {
    width: 31%;
    margin-right: 3%;
}

.travelUpdateFlexRight {
    width: 32%;
}

.travelUpdateFlexLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.travelUpdateFlexLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.travelUpdateFlexLeft select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.travelUpdateFlexCenter h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.travelUpdateFlexCenter input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.travelUpdateFlexRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.travelUpdateFlexRight input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.travelUpdateFlexRight select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.travelUpdate button {
    width: 100%;
    padding: 9px;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    border-radius: 8px;
}

.addmorebtn {
    width: 100%;
    padding: 9px;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    border-radius: 8px;
}

.residency button {
    width: 100%;
    padding: 9px;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    border-radius: 8px;
}

/* wiling */

.willingTravel {
    padding: 20px;
    padding-top: 0%;
}

.willingTravelInner {
    padding: 20px;
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.willingFlex {
    display: flex;
    align-items: flex-start;
}

.willingFlexLeft {
    width: 50%;
    margin-right: 2%;
}

.willingFlexRight {
    width: 50%;
    margin-left: 2%;
}

.willingFlexLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.willingFlexLeft select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.willingFlexLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.willingFlexRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.willingFlexRight select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.willingFlexRight input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.upto {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.upto h3 {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
}

/* residency  */
.HistoryOfTravel {
    padding: 20px;
    padding-bottom: 0px;
}

.residency {
    padding: 15px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-bottom: 20px;

}

.residencyInner {
    width: 100%;
}

.residencyInner h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.residencyInner input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.residencyInner select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.HistoryOfTravelH1 {
    font-size: 16px;
    font-weight: 600;
    opacity: 0.5;
    margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    .travelHistoryDescOverlay {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .travelHistoryDescOverlayPage1 {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .travelHistoryDescOverlay h6 {
        padding: 20px;
        padding-bottom: 0%;
    }

    .travelUpdateFlex {
        flex-direction: column;
    }

    .travelUpdateFlexLeft {
        width: 100%;
    }

    .travelUpdateFlexCenter {
        width: 100%;
    }

    .travelUpdateFlexRight {
        width: 100%;
    }

    .travelHistoryDescOverlay h6 {
        padding: 10px 20px;
        padding-bottom: 0;
    }

    .willingTravel {
        padding-bottom: 0%;
    }

    .willingFlex {
        flex-direction: column;
    }

    .willingFlexLeft {
        width: 100%;
        margin-right: 0%;
    }

    .willingFlexRight {
        width: 100%;
        margin-left: 0%;
    }

    .travelGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .travelHistoryDesc {
        padding: 20px;
    }

    .travelUpdate h6 {
        padding: 0px;
    }

    .innerTravelHistoryOverlay {
        height: 100%;
        overflow-y: scroll;
    }
}