.videoResume {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerVideoResume {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.videoResumeHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoResumeHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.videoResumeHead h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.videoResumeDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
    padding-top: 15px;
    border-radius: 8px;
}

.uploadVedioRes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px dashed var(--borderColor);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 8px;
    background-color: #F8FAFC;
}

.uploadVedioRes h2 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;

}

.uploadVedioRes h3 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    opacity: 0.3;
}

.videoResumeDesc h1 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.5;
}

.vedioDelIcon {
    position: absolute;
    top: 4%;
    right: 2%;
    font-size: 22px;
}

.vedioDelIcon:hover {
    color: red;
}

.vedioNotes {
    display: flex;
    align-items: flex-start;
    /* background-color: #F5F3FF; */
    background-color: #E9E9FF;
    /* background-color: var(--loadingColor); */
    padding: 15px;
    border-radius: 8px;
}

.vedioNotes img {
    margin-right: 10px;
    margin-top: 5px;
    color: var(--textColor);
}

.notes h4 {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
}

.notes {
    padding-left: 10px;
}

.notes ol {
    list-style: number;
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
}

/* overlay */
.vedioOverlay {
    width: 65%;
    height: auto;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vedioResumeButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    padding-top: 5px;
}

.discard {
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--white);

}

.save {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin-left: 20px;
}

.continue {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
}

@media only screen and (max-width: 600px) {
    .vedioResumeButtons {
        flex-direction: column;
        padding: 20px;

    }

    .discard {
        width: 100%;
        padding: 5px 10px;
    }

    .save {
        width: 100%;
        padding: 5px 10px;
        margin-left: 0%;
        margin-top: 15px;
    }

    .vedioOverlay {
        width: 100%;
        height: 100%;
    }

    .innerVideoResumeOverlay {
        height: 100%;
        overflow-y: scroll;
    }
}